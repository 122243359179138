import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
} from 'react';
import appBranding from '@happylife-a/branding';
import firebase from '@happylife-a/firebase';
import webApp from '@happylife-a/web-app';
import webCore from '@happylife-a/web-core';
import { useDisclosure } from '@chakra-ui/react';

const AppDownloadModalContext = createContext({
  isVisible: false,
  setIsVisible: (_isVisible) => {},
});

const LOCAL_STORAGE_APP_DOWNLOAD_KEY = 'AppDownloadModalClosed';

export function AppDownloadVisibilityProvider({ children }) {
  const [isVisible, setIsVisible] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isMobile = webCore.hooks.useMobileCheck();

  const { dispatch } = webApp.contexts.useModal();

  useEffect(() => {
    (async () => {
      const wasModalClosed = await webCore.helpers.storage.getItem(
        LOCAL_STORAGE_APP_DOWNLOAD_KEY
      );
      if (isMobile && !wasModalClosed) {
        setIsVisible(true);
      }
    })();
  }, [isMobile]);

  useEffect(() => {
    if (isVisible && appBranding.tools.features.isEnabled('DownloadAppModal')) {
      onOpen();
    } else {
      onClose();
    }
  }, [isVisible, onOpen, onClose]);

  const setIsVisibleCallback = useCallback(
    async (value, shouldSetLocalStorage = false) => {
      firebase.libraries.analytics.event.log(
        webCore.analytics.ANALYTICS_REDIRECT_MOBILE_POPUP_CLOSE
      );

      setIsVisible(value);
      if (!value && shouldSetLocalStorage) {
        await webCore.helpers.storage.setItem(
          LOCAL_STORAGE_APP_DOWNLOAD_KEY,
          'true'
        );
      }

      dispatch({ type: 'close' });
    },
    []
  );

  const handleDownloadClick = async () => {
    firebase.libraries.analytics.event.log(
      webCore.analytics.ANALYTICS_REDIRECT_MOBILE_POPUP_DOWNLOAD_CLICK
    );
    await webCore.helpers.storage.setItem(
      LOCAL_STORAGE_APP_DOWNLOAD_KEY,
      'true'
    );

    const appDownloadLink = appBranding.configs.environments.getField(
      appBranding.fields.brand.Links.MobileApp.AppDownloadLink
    );

    window.open(appDownloadLink, '_blank');
    dispatch({ type: 'close' });
  };

  useEffect(() => {
    if (isOpen) {
      dispatch({
        type: 'open',
        modal: webApp.constants.ENUM_MODAL_APP_DOWNLOAD,
        props: {
          handleDownloadClick: handleDownloadClick,
          onCloseModal: () => setIsVisibleCallback(false, true),
        },
      });
    }
  }, [isOpen]);

  return (
    <AppDownloadModalContext.Provider
      value={{ isVisible: isVisible, setIsVisible: setIsVisibleCallback }}
    >
      {children}
    </AppDownloadModalContext.Provider>
  );
}

export function useAppDownloadVisibility() {
  return useContext(AppDownloadModalContext);
}
