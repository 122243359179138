import React, { useEffect } from 'react';
import appBranding from '@happylife-a/branding';
import utils from '@happylife-a/utils';
import webCore from '@happylife-a/web-core';
import branch from 'branch-sdk';

const brandEnv = appBranding.configs.environments;
const branchIoFields = appBranding.fields.env.BranchIO;

const liveKey = brandEnv.getField(branchIoFields.LiveKey);
const testKey = brandEnv.getField(branchIoFields.TestKey);

/**
 * @type {import('branch-sdk').InitOptions}
 */
const options = {};

function BranchIoProvider({ children }) {
  useEffect(() => {
    const isProd = webCore.environment.isModeProduction();
    const branchIoKey = isProd ? liveKey : testKey;

    if (!branchIoKey) {
      utils.helpers.logging.warning('Branch.io key is not provided.');
      return;
    }

    branch.init(branchIoKey, options, (error, data) => {
      if (error) {
        utils.helpers.logging.error('Branch.io not initialized', error);
      } else {
        BranchIoProvider.branch = branch;

        if (isProd) {
          utils.helpers.logging.success('Branch.io successfully initialized.');
        } else {
          utils.helpers.logging.success(
            'Branch.io successfully initialized:',
            data
          );
        }
      }
    });
  }, []);

  return <>{children}</>;
}

BranchIoProvider.branch = null;
export default BranchIoProvider;
