import React, { useMemo } from 'react';
import { translations, locales } from '@happylife-a/localization';
import utils from '@happylife-a/utils';
import webApp from '@happylife-a/web-app';
import webCore from '@happylife-a/web-core';
import { ChakraProvider } from '@chakra-ui/react';
import { AppDownloadVisibilityProvider } from '~contexts/AppDownloadModalContext';
import { ChatVisibilityProvider } from '~contexts/ChatVisibilityContext';
import { HeaderScrollableProvider } from '~contexts/HeaderScrollableContext';
import { MobileFooterSpacingProvider } from '~contexts/MobileFooterSpacingContext';
import BranchIoProvider from '~providers/BranchIoProvider';
import modals from './modals';
import guestRoutes from './routes/guest';
import privateRoutes from './routes/private';
import publicRoutes from './routes/public';

const routeList = webApp.routing.buildMultiLangRoutes(locales, {
  guest: guestRoutes,
  private: privateRoutes,
  public: publicRoutes,
});

const allowedUrls = [
  '/seller-privacy-policy',
  '/seller-terms-and-conditions',
  '/privacy-policy',
  '/terms-and-conditions',
  '/contact-us',
];

export default function App({ RouterComponent, ...props }) {
  const maintenance = useMemo(() => {
    if (utils.helpers.environment.isComingSoonMode()) {
      return webApp.providers.MAINTENANCE_TYPES.COMING_SOON;
    }

    return null;
  }, []);

  return (
    <BranchIoProvider>
      <webCore.providers.HappyLifeCoreProvider
        localStorage={{
          encryptable: false, // @TODO: enable for production mode only
          secretKey: import.meta.env.VITE_SECURE_STORAGE_SECRET,
        }}
        firebase={{
          projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
          apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
          messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
          appId: import.meta.env.VITE_FIREBASE_APP_ID,
          measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
        }}
        ExceptionFallbackComponent={(errorProps) => (
          <webApp.UiProvider provider={ChakraProvider}>
            <webApp.screens.Whoops {...errorProps} />
          </webApp.UiProvider>
        )}
        allowGuestUser={true}
      >
        <webApp.UiProvider provider={ChakraProvider}>
          <webApp.providers.HappyLifeWebProvider
            modals={modals}
            translations={translations}
            sentry={{
              dsn: import.meta.env.VITE_SENTRY_DSN,
              environment: 'Webapp',
            }}
            htmlColor={{
              start: webApp.theme.colors.white[50],
              end: webApp.theme.colors.primary[500],
            }}
          >
            <webApp.providers.MaintenanceProvider
              allowedUrls={allowedUrls}
              maintenance={maintenance}
            >
              <webCore.contexts.FavoriteProvider>
                <webCore.contexts.LastViewedProductDetailsProvider>
                  <webCore.contexts.ShoppingCartProvider>
                    <RouterComponent {...props}>
                      <ChatVisibilityProvider
                        forceHidden={maintenance !== null}
                      >
                        <AppDownloadVisibilityProvider>
                          <HeaderScrollableProvider>
                            <MobileFooterSpacingProvider>
                              <webApp.contexts.ModalProvider.ModalPortal />
                              <webApp.components.ScrollToTop />

                              <webApp.router.Routes>
                                {routeList.map((route, index) => (
                                  <webApp.router.Route
                                    key={`AppRouteItem-${route.accessLevel}-${index}-${route.pathname}`}
                                    path={route.pathname}
                                    element={
                                      <webApp.routing.AppRouteItem
                                        {...route}
                                        loginUrl="/"
                                      />
                                    }
                                  />
                                ))}
                              </webApp.router.Routes>
                            </MobileFooterSpacingProvider>
                          </HeaderScrollableProvider>
                        </AppDownloadVisibilityProvider>
                      </ChatVisibilityProvider>
                    </RouterComponent>
                  </webCore.contexts.ShoppingCartProvider>
                </webCore.contexts.LastViewedProductDetailsProvider>
              </webCore.contexts.FavoriteProvider>
            </webApp.providers.MaintenanceProvider>
          </webApp.providers.HappyLifeWebProvider>
        </webApp.UiProvider>
      </webCore.providers.HappyLifeCoreProvider>
    </BranchIoProvider>
  );
}
