import webCore from '@happylife-a/web-core';
import appBranding from '@happylife-a/branding';
import MessagePage from '~pages/chat/[id]';
import ChatThreadPage from '~pages/chat/thread/[id]';

const MainLayout = webCore.helpers.dynamic(() => import('~layouts/Main'));
const ChatLayout = webCore.helpers.dynamic(() => import('~layouts/Chat'));

// account
const CustomerService = webCore.helpers.dynamic(
  () => import('~pages/account/customer-service')
);
const Account = webCore.helpers.dynamic(() => import('~pages/account/index'));
const PaymentMethods = webCore.helpers.dynamic(
  () => import('~pages/account/payment-methods')
);
const Security = webCore.helpers.dynamic(
  () => import('~pages/account/security')
);

const EditProfile = webCore.helpers.dynamic(
  () => import('~pages/account-mobile/edit-profile')
);
const AccountMenu = webCore.helpers.dynamic(
  () => import('~pages/account-mobile/index')
);

// order-history
const OrderHistory = webCore.helpers.dynamic(
  () => import('~pages/order-history/index')
);
const Review = webCore.helpers.dynamic(
  () => import('~pages/order-history/review')
);

// track-order-mobile-variant
const TrackOrderMobileVariant = webCore.helpers.dynamic(
  () => import('~pages/order-history-track-order-mobile-variant/index')
);

// return-replace-items
const ReturnReplaceItem = webCore.helpers.dynamic(
  () => import('~pages/return-replace-items/index')
);

// favorite collections
const Collections = webCore.helpers.dynamic(
  () => import('~pages/collections/index')
);
const ViewCollection = webCore.helpers.dynamic(
  () => import('~pages/view-collection/index')
);

const DeleteAccount = webCore.helpers.dynamic(
  () => import('~pages/account-mobile/delete-account')
);

// grants
const RequestGrant = webCore.helpers.dynamic(
  () => import('~pages/request-grant/index')
);

const GrantsTermsOfUse = webCore.helpers.dynamic(
  () => import('~pages/grant-terms-of-use/index')
);

const showPaymentMethods = webCore.helpers.shouldShowPaymentMethods();

const screens = [
  // favorite collections
  {
    pathname: '/collections',
    screen: Collections,
    layout: MainLayout,
    layoutParams: {
      showMobileSearch: false,
    },
  },
  {
    pathname: '/view-collection/:id',
    screen: ViewCollection,
    layout: MainLayout,
    layoutParams: {
      showMobileSearch: false,
    },
  },
  // account
  {
    pathname: '/account/profile',
    screen: Account,
    layout: MainLayout,
    layoutParams: {
      showMobileSearch: false,
    },
  },
  {
    pathname: '/account',
    screen: AccountMenu,
    layout: MainLayout,
    layoutParams: {
      showMobileSearch: false,
    },
  },
  {
    pathname: '/account/security',
    screen: Security,
    layout: MainLayout,
    layoutParams: {
      showMobileSearch: false,
    },
  },
  ...(showPaymentMethods
    ? [
        {
          pathname: '/account/payment-methods',
          screen: PaymentMethods,
          layout: MainLayout,
          layoutParams: {
            showMobileSearch: false,
          },
        },
      ]
    : []),

  {
    pathname: '/account/edit-profile',
    screen: EditProfile,
    layout: MainLayout,
    layoutParams: {
      showMobileSearch: false,
    },
  },

  ...(appBranding.tools.features.isEnabled('ShowProfileFaq')
    ? [
        {
          pathname: '/account/customer-service',
          screen: CustomerService,
          layout: MainLayout,
          layoutParams: {
            showMobileSearch: false,
          },
        },
      ]
    : []),

  {
    pathname: '/order-history',
    screen: OrderHistory,
    layout: MainLayout,
    layoutParams: {
      showMobileSearch: false,
    },
  },
  {
    pathname: '/order-history/review/:id/order-item-group/:orderItemGroupId',
    screen: Review,
    layout: MainLayout,
  },
  {
    pathname: '/track-order/:id',
    screen: TrackOrderMobileVariant,
    layout: MainLayout,
  },
  {
    pathname: '/return-replace-items/:id',
    screen: ReturnReplaceItem,
    layout: MainLayout,
  },
  {
    pathname: '/chat/:id',
    screen: MessagePage,
    layout: ChatLayout,
  },
  {
    pathname: '/chat/thread/:id',
    screen: ChatThreadPage,
    layout: ChatLayout,
  },
  {
    pathname: '/account/delete-account',
    screen: DeleteAccount,
    layout: MainLayout,
    layoutParams: {
      showMobileSearch: false,
    },
  },
  {
    pathname: '/request-grant',
    screen: RequestGrant,
    layout: MainLayout,
    layoutParams: {
      showMobileSearch: false,
    },
  },
  {
    pathname: '/grant-terms-of-use',
    screen: GrantsTermsOfUse,
  },
];

export default screens;
