import React, { useContext, createContext, useState } from 'react';
import webApp from '@happylife-a/web-app';

const MOBILE_BOTTOM_MENU_HEIGHT = 77;
const MOBILE_BOTTOM_CART_BUTTON_HEIGHT = 64;

const MobileFooterSpacingContext = createContext({
  menuHeight: MOBILE_BOTTOM_MENU_HEIGHT,
  cartButtonHeight: MOBILE_BOTTOM_CART_BUTTON_HEIGHT,
  totalSpacing: 0,
  setShowAddToCartBottom: () => {},
});

export function useMobileFooterSpacing() {
  return useContext(MobileFooterSpacingContext);
}

export function MobileFooterSpacingProvider({ children }) {
  const [showAddToCartBottom, setShowAddToCartBottom] = useState(false);

  const scrollDirection = webApp.hooks.useScrollDirection();

  const totalSpacing =
    MOBILE_BOTTOM_MENU_HEIGHT +
    (showAddToCartBottom ? MOBILE_BOTTOM_CART_BUTTON_HEIGHT : 0);

  return (
    <MobileFooterSpacingContext.Provider
      value={{
        menuHeight: MOBILE_BOTTOM_MENU_HEIGHT,
        cartButtonHeight: MOBILE_BOTTOM_CART_BUTTON_HEIGHT,
        totalSpacing: totalSpacing,
        setShowAddToCartBottom: setShowAddToCartBottom,
        scrollDirection: scrollDirection,
      }}
    >
      {children}
    </MobileFooterSpacingContext.Provider>
  );
}
