import React, { useRef, useCallback } from 'react';
import appBranding from '@happylife-a/branding';
import firebase from '@happylife-a/firebase';
import s3Storage from '@happylife-a/s3-storage';
import utils from '@happylife-a/utils';
import webApp from '@happylife-a/web-app';
import webCore from '@happylife-a/web-core';
import { Flex, Box, useDisclosure, Text, Image } from '@chakra-ui/react';
import { useChatVisibility } from '~contexts/ChatVisibilityContext';

export default function ChannelMessage({
  message,
  chatRoom,
  // messageId,
  onOver,
  onMouseLeave,
  onReply,
  onOpenForwardModal,
  setEditMessage,
  // editMessage,
  onOpenDeleteModal,
  messageCardHeight,
  onAddReaction,
  firebaseRoomId,
  setShowMessageParams,
  firebaseThreadId,
  messagingType,
  roomType,
}) {
  const { user } = webCore.contexts.useUser();
  const { t } = webCore.contexts.useTranslation();
  const toast = webApp.theme.hooks.useToast();

  const isSender = utils.helpers.id.same(message?.sender?.id, user.id);
  const scrollTargetRef = useRef(null);

  const { setIsOpenMessageTab, setIsOpenChatTab } = useChatVisibility();
  const { isOpen, onToggle, onClose } = useDisclosure();

  const chatLib = firebase.libraries.messaging[messagingType];
  const hookLib = webCore.coreHooks.messaging[messagingType];

  const updateMessage = hookLib.useUpdateMessage({
    firebaseRoomId: firebaseRoomId,
    firebaseThreadId: firebaseThreadId,
    messageId: message?.id,
  });

  const messageElements =
    firebase.libraries.messaging.chatting.helpers.parseAndHighlightUrls(
      message.type ===
        firebase.libraries.messaging.chatting.constants
          .MESSAGE_TYPE_FORWARDED && message.forward
        ? message.forward.content
        : message.message.content
    );

  const isOnlyImageMessage = messageElements?.length === 0;

  const clipboard = webApp.hooks.useCopyText();
  const actions = [
    {
      name: t('Reply'),
      icon: <webApp.theme.icons.ReplyIcon fill="none" w={6} h={6} />,
      action: () => onReply(message),
    },
    {
      name: t('Copy'),
      icon: <webApp.theme.icons.CopyTextIcon fill="none" w={6} h={6} />,
      action: async () => {
        const copied = await clipboard.copy(
          message.type ===
            firebase.libraries.messaging.chatting.constants
              .MESSAGE_TYPE_FORWARDED && message.forward
            ? message.forward.content
            : message.message.content
        );

        if (copied) {
          toast({ status: 'success', title: t('Copied') });
        }
      },
    },
    {
      name: t('Edit'),
      icon: <webApp.theme.icons.EditMessageIcon fill="none" w={6} h={6} />,
      action: () => setEditMessage(message),
    },
    {
      name: t('Forward'),
      icon: <webApp.theme.icons.ForwardIcon fill="none" w={6} h={6} />,
      action: () => onOpenForwardModal(message),
    },
    {
      name: t('Delete'),
      icon: <webApp.theme.icons.DeleteIcon fill="none" w={6} h={6} />,
      action: onOpenDeleteModal,
    },
  ];

  const filteredActions = actions.filter((action) => {
    if (action.name === t('Edit')) {
      return isSender && !isOnlyImageMessage;
    } else if (action.name === t('Delete')) {
      return isSender;
    } else if (action.name === t('Copy')) {
      return !isOnlyImageMessage;
    }
    return true;
  });

  const onMouseOut = () => {
    onMouseLeave();
    onClose();
  };

  const images = message.medias || [];
  const reactions = message.reactions || {};

  const toggleReaction = useCallback(
    (reactionName) => {
      if (Object.keys(reactions).length === 1) {
        onMouseLeave();
      }

      const messageInfo = chatLib.message.toggleReaction(
        message,
        reactionName,
        user.id
      );

      updateMessage(messageInfo);
    },
    [message, reactions]
  );

  return (
    <Flex
      px={2}
      py={1}
      borderRadius="4px"
      flexDir="column"
      ref={scrollTargetRef}
      onMouseOver={(e) => onOver(e, message)}
      onMouseLeave={onMouseOut}
      cursor="pointer"
      // bgColor={
      //   messageId === message.id ||
      //   (editMessage && editMessage.id === message.id)
      //     ? 'secondary.50'
      //     : 'transparent'
      // } TODO:: maybe we need this latter
    >
      <Flex
        gap={2}
        alignSelf={!isSender ? 'flex-start' : 'flex-end'}
        alignItems="center"
        justifyContent={!isSender ? 'flex-start' : 'flex-end'}
        w="full"
      >
        {/* {isSender && message.id === messageId && ( */}
        {isSender && (
          <Flex gap={4}>
            <Flex alignItems="center">
              <webApp.components.Reactions
                isOpen={isOpen}
                onClose={onClose}
                onToggle={onToggle}
                isSender={isSender}
                messageCardHeight={messageCardHeight}
                onAddReaction={onAddReaction}
              />
              <Box>
                <webApp.components.ActionsMenu
                  actions={filteredActions}
                  isSender={isSender}
                />
              </Box>
            </Flex>
          </Flex>
        )}
        {!isSender && (
          <>
            {roomType.isCustomerSupport ? (
              <Flex
                w={10}
                h={10}
                fill="white.50"
                boxShadow="0px 0px 4px rgba(0, 0, 0, 0.25)"
                justifyContent="center"
                alignItems="center"
                borderRadius="28px"
                flexShrink={0}
              >
                <Image
                  src={appBranding.configs.assets.getAsset(
                    'logos/svg/square-colored.svg'
                  )}
                  width={35}
                  height={35}
                  objectFit="contain"
                  style={{ color: webApp.theme.colors.primary[500] }}
                />
              </Flex>
            ) : (
              <Flex flexShrink={0}>
                <webApp.components.Avatar
                  image={s3Storage.helpers.read.buildUrlChatRoomAvatar(
                    message?.sender?.avatarKey
                  )}
                  firstName={message?.sender?.name}
                  width={10}
                  height={10}
                  borderRadius={20}
                  color="white.50"
                  background="green.500"
                />
              </Flex>
            )}
          </>
        )}
        <Flex
          maxW={{
            base: '100%',
            md: 450,
          }}
        >
          <webApp.components.ChatBubble isSender={isSender}>
            <Flex flex={1} flexDir="column" gap={1}>
              <webApp.components.MessageCard
                message={message}
                nickname={
                  roomType?.isCustomerSupport
                    ? t('Customer support')
                    : message?.sender?.name
                }
                users={chatRoom?.users}
                isSender={isSender}
                setShowMessageParams={setShowMessageParams}
                firebaseRoomId={firebaseRoomId}
                chatRoom={chatRoom}
                setIsOpenMessageTab={setIsOpenMessageTab}
                setIsOpenChatTab={setIsOpenChatTab}
                messageElements={messageElements}
                roomType={roomType}
              />
              {images &&
                images.length > 0 &&
                message?.type !==
                  firebase.libraries.messaging.chatting.constants
                    .MESSAGE_TYPE_THREAD && (
                  <webApp.components.ImageMessage images={images} />
                )}
              <Flex
                flexDir="row"
                alignSelf={!isSender ? 'flex-start' : 'flex-end'}
              >
                {reactions && (
                  <Flex gap={2}>
                    {Object.keys(reactions).map((name) => {
                      // @TODO: maybe we need this later
                      // const reactionByMe = reactions[name].includes(user.id);
                      const reactionsCount = reactions[name].length;

                      return (
                        <Flex
                          key={`reaction/${name}`}
                          data-reactions-name={name}
                          data-reactions-emoji={
                            firebase.libraries.messaging.chatting.reactions
                              .reactionsByName[name]
                          }
                          data-reactions-count={reactionsCount}
                          onClick={() => toggleReaction(name)}
                          w={6}
                          h={6}
                          justifyContent="center"
                          alignItems="center"
                          bgColor="grey.50"
                          borderRadius="full"
                        >
                          <Text variant="subhead-6">
                            {
                              firebase.libraries.messaging.chatting.reactions
                                .reactionsByName[name]
                            }
                          </Text>
                        </Flex>
                      );
                    })}
                  </Flex>
                )}
              </Flex>
            </Flex>
          </webApp.components.ChatBubble>
        </Flex>
        {/* {!isSender && message.id === messageId && ( */}
        {!isSender && (
          <Flex gap={2}>
            <Box>
              <webApp.components.ActionsMenu
                actions={filteredActions}
                isSender={isSender}
              />
            </Box>
            <Flex alignItems="center">
              <webApp.components.Reactions
                isOpen={isOpen}
                onClose={onClose}
                onToggle={onToggle}
                isSender={isSender}
                messageCardHeight={messageCardHeight}
                onAddReaction={onAddReaction}
              />
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
